import React, { PropsWithChildren } from "react";
import { Grid, Typography } from "@mui/material";
import {
  PlaceHolders,
  TitleSection,
} from "../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { IconPlus, theme } from "@kushki/connect-ui";
import { ErrorsEnum } from "../../shared/infrastructure/enums/ErrorEnum";
import { generalConditionSectionStyles } from "./GeneralConditionSection.styles";
import { IGeneralConditionSectionProps } from "../../shared/infrastructure/interfaces/IGeneralConditionSectionProps";
import { useGeneralSectionStateState } from "./state/useGeneralSectionState.state";
import { Edit, Minus } from "react-feather";
import { FabButton } from "./components/FabButton/FabButton";
import { GenericSelectComponent } from "./components/GenericSelectComponent/GenericSelectComponent";
import { get } from "lodash";
import { SnackbarSmartlinkActive } from "./components/SnackbarSmartlinkActive/SnackbarSmartlinkActive";
import { CONSTITUTIONAL_COUNTRIES } from "../../shared/infrastructure/constants/ConstitutionalCountries";

export const GeneralConditionSection: React.FC<
  IGeneralConditionSectionProps
> = (props: PropsWithChildren<IGeneralConditionSectionProps>) => {
  const classes = generalConditionSectionStyles();
  const {
    errors,
    control,
    selectConditions,
    isRenderAddButton,
    handleAddTextField,
    isRenderRemoveButton,
    isFilledTypeOperator,
    handleRemoveTextField,
    getOperatorsByConditions,
    handleEditCondition,
    retrieveTextFieldsByRuleAndOperator,
    disableConditionAlreadySelected,
    disableUnSelected,
    disableAdd,
    isEditCondition,
    isSmartlinkActive,
    renderAttachFile,
    isConstitutionalCountry,
  } = useGeneralSectionStateState({
    classes,
    generalConditionSectionProps: props,
  });

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Typography variant={"h6"}>{`${PlaceHolders.CONDITION} ${
          !props.isWhiteList ? get(props, "index", 0) + 1 : ""
        }`}</Typography>
      </Grid>
      {isSmartlinkActive && (
        <Grid
          container
          item
          xs={12}
          spacing={2}
          className={classes.containerSnackbarSmartlinkActive}
        >
          <Grid item xs={12} md={8}>
            <SnackbarSmartlinkActive />
          </Grid>
        </Grid>
      )}
      <Grid container item xs={12} spacing={2} className={classes.detail}>
        <Grid item xs={6} md={4}>
          <GenericSelectComponent
            mainProps={props}
            isRuleRequired={undefined}
            className={classes.errorColor}
            isRenderLabel={true}
            placeHolder={PlaceHolders.LABEL}
            name={"label"}
            control={control}
            selectConditions={selectConditions}
            getOperatorsByConditions={undefined}
            errors={errors}
            disableAdd={disableAdd}
            isSpecialCond={props.isSpecialCond}
          />
        </Grid>
        {get(getOperatorsByConditions(), "length", 0) > 0 && (
          <Grid item xs={6} md={4}>
            <GenericSelectComponent
              mainProps={props}
              isRuleRequired={{
                required: {
                  message: ErrorsEnum.REQUIRED_FIELD,
                  value: true,
                },
              }}
              className={classes.errorColor}
              isRenderLabel={false}
              placeHolder={PlaceHolders.OPERATOR}
              name={"operator"}
              control={control}
              selectConditions={undefined}
              getOperatorsByConditions={getOperatorsByConditions()}
              errors={errors}
              disableAdd={disableAdd}
              isSpecialCond={props.isSpecialCond}
            />
          </Grid>
        )}
        {isConstitutionalCountry && (
          <Grid item xs={6} md={4}>
            <GenericSelectComponent
              mainProps={props}
              isRuleRequired={undefined}
              className={classes.errorColor}
              isRenderLabel={true}
              placeHolder={PlaceHolders.COUNTRY_VALUE}
              name={"transactionConstitutionalCountry"}
              control={control}
              selectConditions={CONSTITUTIONAL_COUNTRIES}
              getOperatorsByConditions={undefined}
              errors={errors}
              disableAdd={disableAdd}
              isSpecialCond={props.isSpecialCond}
              // handleOnSelect={handleOnSelect}
            />
          </Grid>
        )}
        <Grid item xs={6} md={4}>
          {renderAttachFile()}
        </Grid>
        {isFilledTypeOperator() && (
          <Grid container item xs={12}>
            {retrieveTextFieldsByRuleAndOperator(get(props, "index", 0))}
          </Grid>
        )}
        <Grid item xs={12} className={classes.buttons}>
          <Grid
            container
            direction={"row"}
            justifyContent={"left"}
            alignItems={"center"}
            spacing={1}
          >
            {isRenderAddButton() && (
              <FabButton
                handleOnClick={handleAddTextField}
                icon={<IconPlus color={"primary"} />}
                title={
                  isEditCondition
                    ? TitleSection.SAVE_SECTION
                    : TitleSection.ADD_SECTION
                }
                disabled={disableConditionAlreadySelected}
                disableUnSelected={disableUnSelected}
              />
            )}
            {isRenderRemoveButton() && (
              <FabButton
                handleOnClick={handleRemoveTextField}
                icon={<Minus color={get(theme, "palette.primary.main")} />}
                title={TitleSection.REMOVE_SECTION}
              />
            )}
            {isRenderRemoveButton() && (
              <FabButton
                handleOnClick={handleEditCondition}
                icon={<Edit color={get(theme, "palette.primary.main")} />}
                title={TitleSection.EDIT_SECTION}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
