import { defaultTo, get } from "lodash";
import {
  IDetailCatalogGeneralRulesComponent,
  IUseDetailCatalogGeneralRulesComponent,
} from "../../../shared/infrastructure/interfaces/IModalCatalogGeneralRule";
import { ICardListProps, ICardTableProps } from "@kushki/connect-ui";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { useEffect, useState } from "react";
import {
  setDetailCatalogGeneralRules,
  setOpenModalCatalogGeneralRules,
} from "../../../store/actionCreators";
import { ConfigurationResponse } from "../../../../types/configuration_response";
import {
  generateSectionCondition,
  generateSectionResume,
  generateSectionSpeed,
} from "../../../utils/utilsFile";
import {
  ALLOWED_GENERAL_CONDITIONS_MODAL,
  ALLOWED_SPECIAL_CONDITIONS_MODAL,
  SubTitleSection,
  TitleSection,
  Values,
} from "../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { LabelEnum } from "../../../shared/infrastructure/enums/LabelEnum";

export const useDetailCatalogGeneralRulesComponentState = (
  props: IDetailCatalogGeneralRulesComponent
): IUseDetailCatalogGeneralRulesComponent => {
  const dispatch = useDispatch();

  const configurationValue: ConfigurationResponse | undefined = useSelector(
    (state: IAppState) => state.configuration
  );
  const [showButton] = useState<boolean>(defaultTo(props.showButton, false));

  const [showSectionSpeed, setShowSectionSpeed] = useState<boolean>(false);
  const [showSpecialSection, setShowSpecialSection] = useState<boolean>(false);

  const [title, setTitle] = useState<string>("");

  const [sectionResume, setSectionResume] = useState<ICardListProps>({
    title: "Configuración",
    orientation: "horizontal",
    separator: false,
    isLoading: true,
    items: [],
  });

  const [sectionConditionGeneral, setSectionConditionGeneral] =
    useState<ICardTableProps>({
      title: "Condiciones generales",
      isLoading: true,
      headers: [],
      rows: [],
    });

  const [sectionSpecialConditions, setSectionSpecialConditions] =
    useState<ICardTableProps>({
      title: LabelEnum.SPECIAL_CONDITION_RULES,
      isLoading: true,
      headers: [],
      rows: [],
    });

  const [sectionSpeed, setSectionSpeed] = useState<ICardTableProps>({
    title: SubTitleSection.RULES_SPEED_CONDITION,
    isLoading: true,
    headers: [],
    rows: [],
  });

  const handleSelectRule = () => {
    dispatch(setOpenModalCatalogGeneralRules({ openModal: false }));
    dispatch(setDetailCatalogGeneralRules(props.selectedRule));
  };

  useEffect(() => {
    if (props.selectedRule) {
      const hasSpecialCond: boolean = get(
        props,
        "selectedRule.rule.hasSpecialCond",
        false
      );

      setTitle(props.selectedRule.name!);
      setShowSectionSpeed(
        get(props, "selectedRule.rule.kind") === Values.RULES_KIND_SPEED
      );

      setShowSpecialSection(hasSpecialCond);
      setSectionSpecialConditions(
        hasSpecialCond
          ? generateSectionCondition(
              props.selectedRule,
              configurationValue,
              ALLOWED_SPECIAL_CONDITIONS_MODAL,
              LabelEnum.SPECIAL_CONDITION_RULES
            )
          : {
              title: LabelEnum.SPECIAL_CONDITION_RULES,
              isLoading: true,
              headers: [],
              rows: [],
            }
      );

      setSectionResume(
        generateSectionResume(props.selectedRule, configurationValue)
      );
      setSectionConditionGeneral(
        generateSectionCondition(
          props.selectedRule,
          configurationValue,
          ALLOWED_GENERAL_CONDITIONS_MODAL,
          TitleSection.RULES_GENERAL_CONDITIONS
        )
      );
      setSectionSpeed(
        generateSectionSpeed(props.selectedRule, configurationValue)
      );
    }
  }, [props.selectedRule]);

  return {
    title,
    sectionResume,
    sectionConditionGeneral,
    sectionSpecialConditions,
    sectionSpeed,
    showSectionSpeed,
    showSpecialSection,
    showButton,
    actions: {
      handleSelectRule,
    },
  };
};
