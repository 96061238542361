import { ISelectNewItemList } from "../interfaces/ISelectNewItemList";

export const CONSTITUTIONAL_COUNTRIES: ISelectNewItemList[] = [
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/brasilFlag%402x.png",
    title: "Brasil",
    field: "Brazil",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/chileFlag%402x.png",
    title: "Chile",
    field: "Chile",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/colombiaFlag%402x.png",
    title: "Colombia",
    field: "Colombia",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/costaRicaFlag%402x.png",
    title: "Costa Rica",
    field: "CostaRica",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/ecuadorFlag%402x.png",
    title: "Ecuador",
    field: "Ecuador",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/salvadorFlag%402x.png",
    title: "El Salvador",
    field: "ElSalvador",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/guatemalaFlag%402x.png",
    title: "Guatemala",
    field: "Guatemala",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/hondurasFlag%402x.png",
    title: "Honduras",
    field: "Honduras",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/mexico%402x.png",
    title: "México",
    field: "Mexico",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/nicaraguaFlag%402x.png",
    title: "Nicaragua",
    field: "Nicaragua",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/panamaFlag%402x.png",
    title: "Panamá",
    field: "Panama",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/peruFlag%402x.png",
    title: "Perú",
    field: "Peru",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/usaFlag%402x.png",
    title: "Estados Unidos",
    field: "EEUU",
  },
  {
    title: "Afganistán",
    field: "Afganistan",
  },
  {
    title: "Albania",
    field: "Albania",
  },
  {
    title: "Alemania",
    field: "Alemania",
  },
  {
    title: "Andorra",
    field: "Andorra",
  },
  {
    title: "Angola",
    field: "Angola",
  },
  {
    title: "Anguila",
    field: "Anguila",
  },
  {
    title: "Antártida",
    field: "Antartida",
  },
  {
    title: "Antigua y Barbuda",
    field: "AntiguayBarbuda",
  },
  {
    title: "Arabia Saudita",
    field: "ArabiaSaudita",
  },
  {
    title: "Argelia",
    field: "Argelia",
  },
  {
    title: "Argentina",
    field: "Argentina",
  },
  {
    title: "Armenia",
    field: "Armenia",
  },
  {
    title: "Aruba",
    field: "Aruba",
  },
  {
    title: "Australia",
    field: "Australia",
  },
  {
    title: "Austria",
    field: "Austria",
  },
  {
    title: "Azerbaiyán",
    field: "Azerbaiyan",
  },
  {
    title: "Bélgica",
    field: "Belgica",
  },
  {
    title: "Bahamas",
    field: "Bahamas",
  },
  {
    title: "Bahrein",
    field: "Bahrein",
  },
  {
    title: "Bangladesh",
    field: "Bangladesh",
  },
  {
    title: "Barbados",
    field: "Barbados",
  },
  {
    title: "Belice",
    field: "Belice",
  },
  {
    title: "Benín",
    field: "Benín",
  },
  {
    title: "Bhután",
    field: "Bhutan",
  },
  {
    title: "Bielorrusia",
    field: "Bielorrusia",
  },
  {
    title: "Birmania",
    field: "Birmania",
  },
  {
    title: "Bolivia",
    field: "Bolivia",
  },
  {
    title: "Bosnia y Herzegovina",
    field: "BosniayHerzegovina",
  },
  {
    title: "Botsuana",
    field: "Botsuana",
  },
  {
    title: "Brunéi",
    field: "Brunei",
  },
  {
    title: "Bulgaria",
    field: "Bulgaria",
  },
  {
    title: "Burkina Faso",
    field: "BurkinaFaso",
  },
  {
    title: "Burundi",
    field: "Burundi",
  },
  {
    title: "Cabo Verde",
    field: "CaboVerde",
  },
  {
    title: "Camboya",
    field: "Camboya",
  },
  {
    title: "Camerún",
    field: "Camerún",
  },
  {
    title: "Canadá",
    field: "Canada",
  },
  {
    title: "Chad",
    field: "Chad",
  },
  {
    title: "China",
    field: "China",
  },
  {
    title: "Chipre",
    field: "Chipre",
  },
  {
    title: "Ciudad del Vaticano",
    field: "CiudaddelVaticano",
  },
  {
    title: "Comoras",
    field: "Comoras",
  },
  {
    title: "República del Congo",
    field: "RepublicadelCongo",
  },
  {
    title: "República Democrática del Congo",
    field: "RepublicaDemocraticadelCongo",
  },
  {
    title: "Corea del Norte",
    field: "CoreadelNorte",
  },
  {
    title: "Corea del Sur",
    field: "CoreadelSur",
  },
  {
    title: "Costa de Marfil",
    field: "CostadeMarfil",
  },
  {
    title: "Croacia",
    field: "Croacia",
  },
  {
    title: "Cuba",
    field: "Cuba",
  },
  {
    title: "Curazao",
    field: "Curazao",
  },
  {
    title: "Dinamarca",
    field: "Dinamarca",
  },
  {
    title: "Dominica",
    field: "Dominica",
  },
  {
    title: "Egipto",
    field: "Egipto",
  },
  {
    title: "Emiratos Árabes Unidos",
    field: "EmiratosArabesUnidos",
  },
  {
    title: "Eritrea",
    field: "Eritrea",
  },
  {
    title: "Eslovaquia",
    field: "Eslovaquia",
  },
  {
    title: "Eslovenia",
    field: "Eslovenia",
  },
  {
    title: "España",
    field: "Espania",
  },
  {
    title: "Estonia",
    field: "Estonia",
  },
  {
    title: "Etiopía",
    field: "Etiopia",
  },
  {
    title: "Filipinas",
    field: "Filipinas",
  },
  {
    title: "Finlandia",
    field: "Finlandia",
  },
  {
    title: "Fiyi",
    field: "Fiyi",
  },
  {
    title: "Francia",
    field: "Francia",
  },
  {
    title: "Gabón",
    field: "Gabon",
  },
  {
    title: "Gambia",
    field: "Gambia",
  },
  {
    title: "Georgia",
    field: "Georgia",
  },
  {
    title: "Ghana",
    field: "Ghana",
  },
  {
    title: "Gibraltar",
    field: "Gibraltar",
  },
  {
    title: "Granada",
    field: "Granada",
  },
  {
    title: "Grecia",
    field: "Grecia",
  },
  {
    title: "Groenlandia",
    field: "Groenlandia",
  },
  {
    title: "Guadalupe",
    field: "Guadalupe",
  },
  {
    title: "Guam",
    field: "Guam",
  },
  {
    title: "Guayana Francesa",
    field: "GuayanaFrancesa",
  },
  {
    title: "Guernsey",
    field: "Guernsey",
  },
  {
    title: "Guinea",
    field: "Guinea",
  },
  {
    title: "Guinea Ecuatorial",
    field: "GuineaEcuatorial",
  },
  {
    title: "Guinea-Bissau",
    field: "GuineaBissau",
  },
  {
    title: "Guyana",
    field: "Guyana",
  },
  {
    title: "Haití",
    field: "Haiti",
  },
  {
    title: "Hong kong",
    field: "Hongkong",
  },
  {
    title: "Hungría",
    field: "Hungria",
  },
  {
    title: "India",
    field: "India",
  },
  {
    title: "Indonesia",
    field: "Indonesia",
  },
  {
    title: "Irán",
    field: "Iran",
  },
  {
    title: "Irak",
    field: "Irak",
  },
  {
    title: "Irlanda",
    field: "Irlanda",
  },
  {
    title: "Isla Bouvet",
    field: "IslaBouvet",
  },
  {
    title: "Isla de Man",
    field: "IsladeMan",
  },
  {
    title: "Isla de Navidad",
    field: "IsladeNavidad",
  },
  {
    title: "Isla Norfolk",
    field: "IslaNorfolk",
  },
  {
    title: "Islandia",
    field: "Islandia",
  },
  {
    title: "Islas Bermudas",
    field: "IslasBermudas",
  },
  {
    title: "Islas Caimán",
    field: "IslasCaiman",
  },
  {
    title: "Islas Cocos (Keeling)",
    field: "IslasCocos",
  },
  {
    title: "Islas Cook",
    field: "IslasCook",
  },
  {
    title: "Islas de Åland",
    field: "IslasdeAland",
  },
  {
    title: "Islas Feroe",
    field: "IslasFeroe",
  },
  {
    title: "Islas Georgias del Sur y Sandwich del Sur",
    field: "IslasGeorgiasdelSurySandwichdelSur",
  },
  {
    title: "Islas Heard y McDonald",
    field: "IslasHeardyMcDonald",
  },
  {
    title: "Islas Maldivas",
    field: "IslasMaldivas",
  },
  {
    title: "Islas Malvinas",
    field: "IslasMalvinas",
  },
  {
    title: "Islas Marianas del Norte",
    field: "IslasMarianasdelNorte",
  },
  {
    title: "Islas Marshall",
    field: "IslasMarshall",
  },
  {
    title: "Islas Pitcairn",
    field: "IslasPitcairn",
  },
  {
    title: "Islas Salomón",
    field: "IslasSalomon",
  },
  {
    title: "Islas Turcas y Caicos",
    field: "IslasTurcasyCaicos",
  },
  {
    title: "Islas Ultramarinas Menores de Estados Unidos",
    field: "IslasUltramarinasMenoresdeEstadosUnidos",
  },
  {
    title: "Islas Vírgenes Británicas",
    field: "IslasVirgenesBritanicas",
  },
  {
    title: "Islas Vírgenes de los Estados Unidos",
    field: "IslasVirgenesdelosEstadosUnidos",
  },
  {
    title: "Israel",
    field: "Israel",
  },
  {
    title: "Italia",
    field: "Italia",
  },
  {
    title: "Jamaica",
    field: "Jamaica",
  },
  {
    title: "Japón",
    field: "Japon",
  },
  {
    title: "Jersey",
    field: "Jersey",
  },
  {
    title: "Jordania",
    field: "Jordania",
  },
  {
    title: "Kazajistán",
    field: "Kazajistan",
  },
  {
    title: "Kenia",
    field: "Kenia",
  },
  {
    title: "Kirguistán",
    field: "Kirguistan",
  },
  {
    title: "Kiribati",
    field: "Kiribati",
  },
  {
    title: "Kuwait",
    field: "Kuwait",
  },
  {
    title: "Líbano",
    field: "Libano",
  },
  {
    title: "Laos",
    field: "Laos",
  },
  {
    title: "Lesoto",
    field: "Lesoto",
  },
  {
    title: "Letonia",
    field: "Letonia",
  },
  {
    title: "Liberia",
    field: "Liberia",
  },
  {
    title: "Libia",
    field: "Libia",
  },
  {
    title: "Liechtenstein",
    field: "Liechtenstein",
  },
  {
    title: "Lituania",
    field: "Lituania",
  },
  {
    title: "Luxemburgo",
    field: "Luxemburgo",
  },
  {
    title: "Mónaco",
    field: "Monaco",
  },
  {
    title: "Macao",
    field: "Macao",
  },
  {
    title: "Macedônia",
    field: "Macedônia",
  },
  {
    title: "Madagascar",
    field: "Madagascar",
  },
  {
    title: "Malasia",
    field: "Malasia",
  },
  {
    title: "Malawi",
    field: "Malawi",
  },
  {
    title: "Mali",
    field: "Mali",
  },
  {
    title: "Malta",
    field: "Malta",
  },
  {
    title: "Marruecos",
    field: "Marruecos",
  },
  {
    title: "Martinica",
    field: "Martinica",
  },
  {
    title: "Mauricio",
    field: "Mauricio",
  },
  {
    title: "Mauritania",
    field: "Mauritania",
  },
  {
    title: "Mayotte",
    field: "Mayotte",
  },
  {
    title: "Micronesia",
    field: "Micronesia",
  },
  {
    title: "Moldavia",
    field: "Moldavia",
  },
  {
    title: "Mongolia",
    field: "Mongolia",
  },
  {
    title: "Montenegro",
    field: "Montenegro",
  },
  {
    title: "Montserrat",
    field: "Montserrat",
  },
  {
    title: "Mozambique",
    field: "Mozambique",
  },
  {
    title: "Namibia",
    field: "Namibia",
  },
  {
    title: "Nauru",
    field: "Nauru",
  },
  {
    title: "Nepal",
    field: "Nepal",
  },
  {
    title: "Niger",
    field: "Niger",
  },
  {
    title: "Nigeria",
    field: "Nigeria",
  },
  {
    title: "Niue",
    field: "Niue",
  },
  {
    title: "Noruega",
    field: "Noruega",
  },
  {
    title: "Nueva Caledonia",
    field: "NuevaCaledonia",
  },
  {
    title: "Nueva Zelanda",
    field: "NuevaZelanda",
  },
  {
    title: "Omán",
    field: "Oman",
  },
  {
    title: "Países Bajos",
    field: "PaisesBajos",
  },
  {
    title: "Pakistán",
    field: "Pakistan",
  },
  {
    title: "Palau",
    field: "Palau",
  },
  {
    title: "Palestina",
    field: "Palestina",
  },
  {
    title: "Papúa Nueva Guinea",
    field: "PapuaNuevaGuinea",
  },
  {
    title: "Paraguay",
    field: "Paraguay",
  },
  {
    title: "Polinesia Francesa",
    field: "PolinesiaFrancesa",
  },
  {
    title: "Polonia",
    field: "Polonia",
  },
  {
    title: "Portugal",
    field: "Portugal",
  },
  {
    title: "Puerto Rico",
    field: "PuertoRico",
  },
  {
    title: "Qatar",
    field: "Qatar",
  },
  {
    title: "Reino Unido",
    field: "ReinoUnido",
  },
  {
    title: "República Centroafricana",
    field: "RepublicaCentroafricana",
  },
  {
    title: "República Checa",
    field: "RepublicaCheca",
  },
  {
    title: "República Dominicana",
    field: "RepublicaDominicana",
  },
  {
    title: "República de Sudán del Sur",
    field: "RepublicadeSudandelSur",
  },
  {
    title: "Reunión",
    field: "Reunion",
  },
  {
    title: "Ruanda",
    field: "Ruanda",
  },
  {
    title: "Rumanía",
    field: "Rumania",
  },
  {
    title: "Rusia",
    field: "Rusia",
  },
  {
    title: "Sahara Occidental",
    field: "SaharaOccidental",
  },
  {
    title: "Samoa",
    field: "Samoa",
  },
  {
    title: "Samoa Americana",
    field: "SamoaAmericana",
  },
  {
    title: "San Bartolomé",
    field: "SanBartolome",
  },
  {
    title: "San Cristóbal y Nieves",
    field: "SanCristobalyNieves",
  },
  {
    title: "San Marino",
    field: "SanMarino",
  },
  {
    title: "San Martín (Francia)",
    field: "SanMartinFrancia",
  },
  {
    title: "San Pedro y Miquelón",
    field: "SanPedroyMiquelon",
  },
  {
    title: "San Vicente y las Granadinas",
    field: "SanVicenteylasGranadinas",
  },
  {
    title: "Santa Elena",
    field: "SantaElena",
  },
  {
    title: "Santa Lucía",
    field: "SantaLucia",
  },
  {
    title: "Santo Tomé y Príncipe",
    field: "SantoTomeyPrincipe",
  },
  {
    title: "Senegal",
    field: "Senegal",
  },
  {
    title: "Serbia",
    field: "Serbia",
  },
  {
    title: "Seychelles",
    field: "Seychelles",
  },
  {
    title: "Sierra Leona",
    field: "SierraLeona",
  },
  {
    title: "Singapur",
    field: "Singapur",
  },
  {
    title: "Sint Maarten",
    field: "SintMaarten",
  },
  {
    title: "Siria",
    field: "Siria",
  },
  {
    title: "Somalia",
    field: "Somalia",
  },
  {
    title: "Sri lanka",
    field: "Srilanka",
  },
  {
    title: "Sudáfrica",
    field: "Sudafrica",
  },
  {
    title: "Sudán",
    field: "Sudan",
  },
  {
    title: "Suecia",
    field: "Suecia",
  },
  {
    title: "Suiza",
    field: "Suiza",
  },
  {
    title: "Surinám",
    field: "Surinam",
  },
  {
    title: "Svalbard y Jan Mayen",
    field: "SvalbardyJan Mayen",
  },
  {
    title: "Swazilandia",
    field: "Swazilandia",
  },
  {
    title: "Tayikistán",
    field: "Tayikistan",
  },
  {
    title: "Tailandia",
    field: "Tailandia",
  },
  {
    title: "Taiwán",
    field: "Taiwan",
  },
  {
    title: "Tanzania",
    field: "Tanzania",
  },
  {
    title: "Territorio Británico del Océano Índico",
    field: "TerritorioBritanicodelOceanoIndico",
  },
  {
    title: "Territorios Australes y Antárticas Franceses",
    field: "TerritoriosAustralesyAntarticasFranceses",
  },
  {
    title: "Timor Oriental",
    field: "TimorOriental",
  },
  {
    title: "Togo",
    field: "Togo",
  },
  {
    title: "Tokelau",
    field: "Tokelau",
  },
  {
    title: "Tonga",
    field: "Tonga",
  },
  {
    title: "Trinidad y Tobago",
    field: "TrinidadyTobago",
  },
  {
    title: "Tunez",
    field: "Tunez",
  },
  {
    title: "Turkmenistán",
    field: "Turkmenistan",
  },
  {
    title: "Turquía",
    field: "Turquia",
  },
  {
    title: "Tuvalu",
    field: "Tuvalu",
  },
  {
    title: "Ucrania",
    field: "Ucrania",
  },
  {
    title: "Uganda",
    field: "Uganda",
  },
  {
    title: "Uruguay",
    field: "Uruguay",
  },
  {
    title: "Uzbekistán",
    field: "Uzbekistan",
  },
  {
    title: "Vanuatu",
    field: "Vanuatu",
  },
  {
    title: "Venezuela",
    field: "Venezuela",
  },
  {
    title: "Vietnam",
    field: "Vietnam",
  },
  {
    title: "Wallis y Futuna",
    field: "WallisyFutuna",
  },
  {
    title: "Yemen",
    field: "Yemen",
  },
  {
    title: "Yibuti",
    field: "Yibuti",
  },
  {
    title: "Zambia",
    field: "Zambia",
  },
  {
    title: "Zimbabue",
    field: "Zimbabue",
  },
];
