import React from "react";
import {
  FormGroup,
  FormControlLabel,
  Box,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  TextField,
  Radio,
  RadioGroup,
  Switch,
  InputAdornment,
} from "@mui/material";
import { ConfigurationResponse } from "../../../types/configuration_response";
import { ErrorsEnum } from "../../shared/infrastructure/enums/ErrorEnum";
import {
  HelperText,
  Labels,
  PlaceHolders,
  SubTitleSection,
  Values,
} from "../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { Controller, useFormContext, UseFormMethods } from "react-hook-form";
import { ISecurityRuleForm } from "../../shared/infrastructure/interfaces/ISecurityRuleForm";
import { retrieveFormError } from "../../utils/utilsFile";
import { createStyles, makeStyles } from "@mui/styles";
import { useSpeedConditionSectionState } from "./state/useSpeedConditionSectionState";
import { get } from "lodash";

export interface SpeedConditionSectionProps {
  configuration?: ConfigurationResponse;
  country: string[];
  form?: UseFormMethods<ISecurityRuleForm>;
  setIsDisabledSpecial: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabledSpeed: boolean;
  fieldsCond: Object[];
}

const useStyles = makeStyles(() =>
  createStyles({
    errorColor: {
      color: "rgba(226,71,99,1)",
    },
    size15: {
      fontSize: "15px !important",
    },
    width: {
      minWidth: 120,
    },
  })
);

export const SpeedConditionSection = (props: SpeedConditionSectionProps) => {
  const { errors, control } = useFormContext();
  const classes = useStyles();
  const {
    handle: { enableSpeed, handleSpeedType, handleChangeStateTrx },
    currency,
    currencyLabel,
    isCountTransaction,
    isSpeed,
    speedTypeLabel,
    stateTrx,
    isSpecial,
  } = useSpeedConditionSectionState(props);

  const sizeSpeedTextField = {
    xs: get(currency, "length", 0) > 1 ? 8 : 12,
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={4} mt={3}>
        <Typography variant="h6">
          {SubTitleSection.RULES_SPEED_CONDITION}
        </Typography>
      </Grid>
      <Grid item xs={6} mt={3}>
        <Box>
          <FormControl variant="outlined" fullWidth>
            <Controller
              name="kind"
              control={control}
              render={({ ...selectProps }) => (
                <FormGroup {...selectProps}>
                  <FormControlLabel
                    defaultChecked={false}
                    control={
                      <Switch
                        color="primary"
                        size="small"
                        value={
                          isSpeed
                            ? Values.RULES_KIND_STRICT
                            : Values.RULES_KIND_SPEED
                        }
                        disabled={isSpecial || props.isDisabledSpeed}
                        checked={isSpecial || isSpeed}
                        onChange={(evt) =>
                          enableSpeed(
                            evt.target.value === Values.RULES_KIND_SPEED
                          )
                        }
                      />
                    }
                    label={""}
                  />
                </FormGroup>
              )}
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {isSpeed && (
          <Box>
            <Box>
              <Typography color="text.dark" variant="subtitle2">
                {SubTitleSection.RULES_SECONDARY_SPEED}
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography
                color="text.dark"
                variant="body2"
                className={classes.size15}
              >
                {SubTitleSection.RULES_SPEED_TYPE}:
              </Typography>
              <RadioGroup
                aria-label="speedType"
                name="speedType"
                defaultValue={
                  isSpecial ? Values.RULES_SPEED_COUNT : speedTypeLabel
                }
                value={isSpecial ? Values.RULES_SPEED_COUNT : speedTypeLabel}
                onChange={handleSpeedType}
                row
              >
                <FormControlLabel
                  value={Values.RULES_SPEED_COUNT}
                  control={<Radio color="primary" />}
                  label={Labels.RULES_SPEED_COUNT}
                />
                <FormControlLabel
                  value={Values.RULES_SPEED_SUM}
                  control={<Radio color="primary" />}
                  label={Labels.RULES_SPEED_SUM}
                  disabled={isSpecial}
                />
              </RadioGroup>
            </Box>
            <Grid mt={1} item xs={12}>
              <Typography
                color="text.dark"
                variant="body2"
                className={classes.size15}
              >
                {SubTitleSection.RULES_TRX_SPEED} {speedTypeLabel}:
              </Typography>
              {isCountTransaction ? (
                <Grid item mt={2} xs={12} md={6} lg={4}>
                  <Box mt={2}>
                    <FormControl variant="outlined" fullWidth>
                      <Controller
                        name="trxCount"
                        control={control}
                        rules={{
                          required: {
                            message: ErrorsEnum.REQUIRED_FIELD,
                            value: true,
                          },
                          pattern: {
                            value: new RegExp("^[0-9]*$"),
                            message: HelperText.SPEED_COUNT_HELPER,
                          },
                        }}
                        render={({ ...selectProps }) => (
                          <TextField
                            {...selectProps}
                            id="standard-number"
                            label={PlaceHolders.QUANTITY}
                            type="number"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                        )}
                      />
                      <FormHelperText
                        className={classes.errorColor}
                        error={retrieveFormError(errors, "trxCount")[0]}
                      >
                        {retrieveFormError(errors, "trxCount")[1]}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </Grid>
              ) : (
                <Grid container mt={2} spacing={1}>
                  <Grid item xs={sizeSpeedTextField.xs} md={6} lg={4}>
                    <FormControl variant="outlined" fullWidth>
                      <Controller
                        name="amountSum"
                        control={control}
                        rules={{
                          required: true,
                          pattern: {
                            value: new RegExp("^[0-9]\\d*(?:\\.\\d{1,6})?$"),
                            message: HelperText.SPEED_NUMBER_INVALID,
                          },
                        }}
                        render={({ ...selectProps }) => (
                          <TextField
                            {...selectProps}
                            id="monto"
                            label={PlaceHolders.AMOUNT}
                            type="number"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={
                              currency.length <= 1
                                ? {
                                    inputProps: { min: 0 },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {currencyLabel}
                                      </InputAdornment>
                                    ),
                                  }
                                : { inputProps: { min: 0 } }
                            }
                          />
                        )}
                      />
                      <FormHelperText
                        className={classes.errorColor}
                        error={retrieveFormError(errors, "amountSum")[0]}
                      >
                        {retrieveFormError(errors, "amountSum")[1]}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {currency.length > 1 && (
                    <Grid item xs={4} lg={2}>
                      <FormControl variant="outlined">
                        <Controller
                          name="currency"
                          control={control}
                          rules={{
                            required: {
                              message: ErrorsEnum.REQUIRED_FIELD,
                              value: true,
                            },
                          }}
                          render={({ ...selectProps }) => (
                            <Box>
                              <InputLabel>{Labels.CURRENCY}</InputLabel>
                              <Select
                                {...selectProps}
                                name={"currency"}
                                id={"currency"}
                                label={Labels.CURRENCY}
                                className={classes.width}
                              >
                                {currency.map(
                                  (
                                    items: { label?: string; value?: string },
                                    index: number
                                  ) => (
                                    <MenuItem key={index} value={items.value}>
                                      {items.label}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </Box>
                          )}
                        />
                        <FormHelperText
                          className={classes.errorColor}
                          error={retrieveFormError(errors, "currency")[0]}
                        >
                          {retrieveFormError(errors, "currency")[1]}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              )}
              <Typography
                mt={2}
                color="text.dark"
                variant="body2"
                className={classes.size15}
              >
                {SubTitleSection.RULES_PERIODICITY_SPEED}
              </Typography>
              <Grid
                item
                xs={sizeSpeedTextField.xs}
                md={6}
                lg={4}
                mt={2}
                spacing={1}
              >
                <Controller
                  name="periodicity"
                  control={control}
                  rules={{
                    required: {
                      message: ErrorsEnum.REQUIRED_FIELD,
                      value: true,
                    },
                  }}
                  render={({ ...selectProps }) => (
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>{Labels.PERIODICITY}</InputLabel>
                      <Select
                        {...selectProps}
                        name={"periodicity"}
                        id={"periodicity"}
                        label={Labels.PERIODICITY}
                      >
                        {props.configuration?.periodicity!.map(
                          (
                            periodicityValue: {
                              title?: string;
                              value?: string;
                            },
                            index: number
                          ) => (
                            <MenuItem
                              key={index}
                              value={periodicityValue.value}
                            >
                              {periodicityValue.title}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      <FormHelperText
                        className={classes.errorColor}
                        error={retrieveFormError(errors, "periodicity")[0]}
                      >
                        {retrieveFormError(errors, "periodicity")[1]}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
            <Box mt={2}>
              <Typography
                color="text.dark"
                variant="body2"
                className={classes.size15}
              >
                {SubTitleSection.RULES_TRX_STATUS_SPEED}
              </Typography>
              <FormControl variant="outlined" fullWidth>
                <Controller
                  name="stateTrx"
                  control={control}
                  value={stateTrx}
                  rules={{
                    required: {
                      message: ErrorsEnum.REQUIRED_FIELD,
                      value: true,
                    },
                  }}
                  render={({ ...selectProps }) => (
                    <RadioGroup
                      {...selectProps}
                      aria-label={SubTitleSection.RULES_TRX_STATUS_SPEED}
                      name="stateTrx"
                      row
                      value={stateTrx}
                    >
                      <FormControlLabel
                        value={Labels.APPROVAL}
                        control={<Radio color="primary" />}
                        label="Aprobada"
                        disabled={isSpecial}
                        onChange={handleChangeStateTrx}
                      />
                      <FormControlLabel
                        value={Labels.DECLINED}
                        control={<Radio color="primary" />}
                        label="Declinada"
                        onChange={handleChangeStateTrx}
                      />
                      <FormControlLabel
                        value={Labels.ALL}
                        control={<Radio color="primary" />}
                        label="Ambos"
                        disabled={isSpecial}
                        onChange={handleChangeStateTrx}
                      />
                    </RadioGroup>
                  )}
                />
                <FormHelperText
                  className={classes.errorColor}
                  error={retrieveFormError(errors, "stateTrx")[0]}
                >
                  {retrieveFormError(errors, "stateTrx")[1]}
                </FormHelperText>
              </FormControl>
            </Box>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
export default SpeedConditionSection;
