import {
  getHierarchyNodes,
  getRuleRequestCatalog,
  setGeneralRule,
  setOpenConfirmGeneralRuleModal,
  setOpenConfirmModal,
  setSecurityRule,
} from "../../../store/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import {
  Control,
  FieldErrors,
  SubmitHandler,
  useFormContext,
  UseFormMethods,
} from "react-hook-form";
import {
  IConditionForm,
  ISecurityRuleForm,
} from "../../../shared/infrastructure/interfaces/ISecurityRuleForm";
import { useEffect, useState } from "react";
import {
  defaultTo,
  get,
  includes,
  isEmpty,
  isEqual,
  isUndefined,
  set,
  unset,
} from "lodash";
import { IAppState } from "../../../store/reducer";
import { RuleDetail } from "../../../../types/rule_detail";
import {
  GroupEnum,
  SubTypeEnum,
} from "../../../shared/infrastructure/enums/CreateRuleConstans";
import {
  Condition,
  SecurityRuleData,
  SpeedCondition,
} from "../../../../types/security_rule_data";
import { useLocation, useParams } from "react-router-dom";
import { IUseParams } from "../../../shared/infrastructure/interfaces/IUseParams";
import {
  checkIsGeneralRule,
  isFormEditMode,
  isFormWhiteListMode,
} from "../../../utils/utilsFile";
import { RuleGeneralRequest } from "../../../../types/search_rule_catalog_response";
import {
  Labels,
  Values,
} from "../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { LabelEnum } from "../../../shared/infrastructure/enums/LabelEnum";
import { PathsEnum } from "../../../shared/infrastructure/enums/PathsEnum";

export interface IUseActionButtonsState {
  handleCreate: (event: any) => void;
  disableButton: boolean;
  isEditMode: boolean;
}

export interface IActionButtonsProps {
  fields?: Object[];
  form?: UseFormMethods<ISecurityRuleForm>;
  onCancel: () => void;
  handleSendToReview: () => void;
}

export const useActionButtonsState = (
  props: IActionButtonsProps
): IUseActionButtonsState => {
  const dispatch = useDispatch();
  const { ruleId, id, ruleName } = useParams<IUseParams>();
  const { getValues, watch } = useFormContext();
  const kindUpdate: { kind: string } = watch(["kind"]);
  const urlPath = useLocation();

  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [sendRequest, setSendRequest] = useState(false);
  const ruleDetail: RuleDetail | undefined = useSelector(
    (state: IAppState) => state.ruleDetails
  );
  const generalRuleDetail: SecurityRuleData | undefined = useSelector(
    (state: IAppState) => state.generalRule
  );
  const ruleData: SecurityRuleData | undefined = useSelector(
    (state: IAppState) => state.rule
  );
  const isLoading: boolean = useSelector((state: IAppState) =>
    defaultTo(state.isLoading, false)
  );
  const selectedRule: RuleGeneralRequest | undefined = useSelector(
    (state: IAppState) => state.detailCatalogGeneralRules
  );
  const selectedSubType: string = watch("subType");
  const commerceTypeSelected: string = watch("commerceType");

  const filterErrors = (
    errors: FieldErrors<ISecurityRuleForm>
  ): FieldErrors<ISecurityRuleForm> => {
    const newErrors: FieldErrors<ISecurityRuleForm> = errors;

    Object.keys(errors).map((ruleKey: string) => {
      const msgPath: string = `${ruleKey}.message`;
      if (
        includes(ruleKey, LabelEnum.CARD_ID) &&
        isEqual(get(errors, msgPath), Labels.NOT_APPLICABLE)
      )
        unset(newErrors, ruleKey);
    });

    return newErrors;
  };

  const checkSpecialConditions = (validFieldsRef: any): boolean => {
    const subKind: string = getValues(PathsEnum.SUB_KIND);
    const hasSpecialCond: boolean = isEqual(subKind, Values.RULES_KIND_SPECIAL);
    let isValid: boolean = false;

    Object.keys(validFieldsRef).map((ruleKey: string) => {
      if (includes(ruleKey, `${LabelEnum.PROCESSOR_CODE}-`)) isValid = true;
    });

    return hasSpecialCond ? isValid : true;
  };

  useEffect(() => {
    if (!props.form) return;
    const control: Control<ISecurityRuleForm> = props.form.control;
    const fieldsWithValidationRef = get(
      control,
      "fieldsWithValidationRef.current",
      {}
    );
    const validFieldsRef = get(control, "validFieldsRef.current", {});
    delete fieldsWithValidationRef.condition;
    delete validFieldsRef.condition;
    const completedFields: boolean = isEqual(
      validFieldsRef,
      fieldsWithValidationRef
    );
    const isValidSpecialConditions: boolean =
      checkSpecialConditions(validFieldsRef);
    const conditionValues: Condition[] = getValues("condition");
    const isFilledValues: boolean =
      conditionValues &&
      conditionValues.filter(
        (data: Condition) =>
          !isEmpty(data.label) && !isEmpty(get(data, "operator", ""))
      ).length > 0;

    if (isFormEditMode(id) || isFormWhiteListMode()) {
      setDisableButton(!isFilledValues);
      return;
    }
    if (isFormEditMode(ruleId || ruleName) || !isUndefined(ruleData)) {
      const errors: FieldErrors<ISecurityRuleForm> = filterErrors(
        get(props, "form.errors", {})
      );

      setDisableButton(
        !isEmpty(errors) ||
          get(props, "fields", []).length === 1 ||
          !isValidSpecialConditions
      );
    } else {
      setDisableButton(
        !isEmpty(props.form.errors) ||
          !completedFields ||
          props.fields!.length === 1 ||
          !isValidSpecialConditions
      );
      if (!isUndefined(selectedRule)) setDisableButton(false);
    }
  }, [props.fields, props.form, kindUpdate]);

  useEffect(() => {
    if (!isEmpty(generalRuleDetail)) {
      dispatch(
        getRuleRequestCatalog({
          limit: 100,
          offset: 0,
          name: get(generalRuleDetail, "name"),
          filter: {
            generalAction: get(generalRuleDetail, "generalAction"),
            partnerValidator: get(generalRuleDetail, "provider"),
            code: get(generalRuleDetail, "code"),
            message: get(generalRuleDetail, "message"),
          },
          sort: { field: "created", order: "asc" },
        })
      );
      dispatch(setOpenConfirmGeneralRuleModal(true));
    }
  }, [generalRuleDetail]);

  useEffect(() => {
    if (!isEmpty(ruleData) && sendRequest) {
      if (
        isFormWhiteListMode() &&
        commerceTypeSelected === SubTypeEnum.COMMERCE_WITHOUT_HIERARCHY &&
        (selectedSubType === SubTypeEnum.COMMERCE ||
          selectedSubType === SubTypeEnum.COUNTRY)
      ) {
        dispatch(setOpenConfirmModal(false));
        props.handleSendToReview();
        return;
      }

      if (
        !isEmpty(get(ruleDetail, "entityName", "")) &&
        get(ruleDetail, "entityName", "") != GroupEnum.BRANCH &&
        get(ruleDetail, "subType", "") === SubTypeEnum.COMMERCE
      ) {
        dispatch(setOpenConfirmModal(true));
        dispatch(
          getHierarchyNodes({
            entityName: get(ruleDetail, "entityName", ""),
            nodeId: get(ruleDetail, "nodeId", ""),
            rootId: get(ruleDetail, "rootId", ""),
          })
        );
      } else {
        props.handleSendToReview();
      }
    }
    setSendRequest(false);
  }, [ruleData, selectedSubType, commerceTypeSelected]);

  function buildSecurityRuleDate(
    formValues: SubmitHandler<ISecurityRuleForm>
  ): SecurityRuleData {
    const subKind: string = watch(PathsEnum.SUB_KIND);
    const getValuesEditMode: IConditionForm[] = watch("condition");
    const specialConditions: IConditionForm[] = watch("specialCondition", []);
    const data: SubmitHandler<ISecurityRuleForm> | {} = defaultTo(
      formValues,
      {}
    );

    const speedCondition: SpeedCondition = {
      amountSum: get(formValues, "amountSum"),
      currency: get(formValues, "currency"),
      periodicity: get(formValues, "periodicity"),
      stateTrx: get(formValues, "stateTrx"),
      trxCount: get(formValues, "trxCount"),
    };

    if (
      specialConditions.length > 0 &&
      isEqual(subKind, Values.RULES_KIND_SPECIAL)
    ) {
      set(data, "kind", Values.RULES_KIND_SPEED);
      set(data, "hasSpecialCond", isEqual(subKind, Values.RULES_KIND_SPECIAL));
    }

    props.fields?.splice(props.fields?.length - 1, 1);
    if (isFormWhiteListMode()) {
      set(data, "condition", getValuesEditMode);
    } else {
      set(data, "condition", props.fields);
      set(data, "specialCondition", specialConditions);
    }

    set(data, "speedCondition", speedCondition);
    set(data, "kind", defaultTo(get(data, "kind"), Values.RULES_KIND_STRICT));

    return data as SecurityRuleData;
  }

  const handleCreate = (formValues: SubmitHandler<ISecurityRuleForm>): void => {
    if (!props.form) return;

    const data: SecurityRuleData = buildSecurityRuleDate(formValues);

    if (checkIsGeneralRule(urlPath.pathname)) {
      dispatch(setGeneralRule(data));
    } else {
      setSendRequest(true);
      dispatch(setSecurityRule(data));
    }
  };

  return {
    handleCreate,
    disableButton: disableButton || isLoading,
    isEditMode: isFormEditMode(ruleId || ruleName),
  };
};
