import { DeepMap, FieldError } from "react-hook-form";
import {
  defaultTo,
  get,
  includes,
  isEmpty,
  isEqual,
  isUndefined,
  join,
  pick,
  set,
  size,
  split,
  toNumber,
} from "lodash";
import { RolesEnum } from "../shared/infrastructure/enums/RolesEnum";
import { ItemSearchRuleResponse } from "../../types/search_rule_response";
import { format } from "date-fns-tz";
import { FormatDateEnum } from "../shared/infrastructure/enums/FormatDateEnum";
import { ITableCellProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { ICellRow } from "@kushki/connect-ui/dist/Components/Atoms/Table/CellRow/CellRow.interfaces";
import { StatusEnum } from "../shared/infrastructure/enums/StatusEnum";
import { ColorEnum } from "../shared/infrastructure/enums/ColorEnum";
import { Country } from "../../types/country_response";
import {
  CONDITION_FIELDS,
  CONDITION_LABEL,
  TableFieldsEnum,
  TableValueEnum,
  TEXT_TRANSFORM_COLUMNS,
} from "../shared/infrastructure/enums/DataTableEnum";
import { TableBodyCellEnum } from "../components/DataTableComponent/DataTableComponent";
import { LabelEnum } from "../shared/infrastructure/enums/LabelEnum";
import { IWhiteListCondition } from "../shared/infrastructure/interfaces/IWhiteListCondition";
import { FieldsRule } from "../shared/infrastructure/constants/RuleRequestConstants";
import { Condition, SecurityRuleData } from "../../types/security_rule_data";
import { RuleRequest } from "../../types/rule_request";
import {
  ActionEnum,
  ALLOWED_FIELD_RESET_FORM,
  COUNTRIES_CONDITIONS,
  COUNTRY_CONDITIONS,
  GeneralActionEnum,
  HIDE_CUSTOMER_CONDITIONS,
  HIDE_SECURITY_GENERIC_AUTHENTICATE_CONDITIONS,
  HIDE_SECURITY_RULES_3DS_CONDITIONS,
  HIDE_SECURITY_RULES_CONDITIONS,
  HIDE_SECURITY_RULES_OTP_CONDITIONS,
  HIDE_WHITE_LIST_CONDITIONS,
  IOptionSelection,
  NOT_RULE_CONDITIONS,
  OPERATORS,
  SPECIAL_CONDITIONS,
  SubTitleSection,
  TitleSection,
  TRANSACTION_STATUS,
  UrlPaths,
  VALID_GENERAL_ACTIONS,
  Values,
} from "../shared/infrastructure/constants/RuleRequestManagerConstants";
import { SearchRuleByIdResponse } from "../../types/search_rule_by_id_response";
import { SearchRequestRuleByIdResponse } from "../../types/search_request_rule_by_id_response";
import {
  IConditionForm,
  ISecurityRuleForm,
} from "../shared/infrastructure/interfaces/ISecurityRuleForm";
import {
  ICardListProps,
  ICardTableProps,
  OptionsSelectSearchBy,
} from "@kushki/connect-ui";
import {
  GroupEnum,
  RulesSchema,
  SubTypeEnum,
  TypeToSubtypeRules,
} from "../shared/infrastructure/enums/CreateRuleConstans";
import { Dispatch, SetStateAction } from "react";
import { RuleGeneralRequest } from "../../types/search_rule_catalog_response";
import {
  Codes,
  Conditions,
  ConfigurationResponse,
  GeneralActions,
  Messages,
  Operators,
  Periodicity,
  Providers,
} from "../../types/configuration_response";
import { GetRuleByIdResponse } from "../../types/get_rule_by_id";
import { PathsEnum } from "../shared/infrastructure/enums/PathsEnum";
import { RuleDetail } from "../../types/rule_detail";
import { Continent, GeographyList, Region } from "../../types/geography_list";
import {
  TEXT_TRANSFORM_MODAL,
  ValueEnum,
} from "../shared/infrastructure/enums/ModalEnum";
import { SECURE_SERVICE_NAME } from "../shared/infrastructure/constants/SecureServiceCatalog";

export const retrieveFormError = (
  errors: DeepMap<Record<string, any>, FieldError>,
  pathProperty: string
): [boolean, string] => {
  const isFormError: boolean = !isUndefined(get(errors, pathProperty));
  return [
    isFormError,
    isFormError ? get(errors, `${pathProperty}.message`) : "",
  ];
};

export const isRuleOperatorOrRuleAdmin = (): boolean => {
  const roles: object = JSON.parse(localStorage.getItem("roles")!);
  const admin_roles: RolesEnum[] = [
    RolesEnum.BackofficeRuleOperator,
    RolesEnum.BackofficeRuleAdmin,
  ];
  const found_role: RolesEnum | undefined = admin_roles.find(
    (role: RolesEnum) => get(roles, role, false)
  );

  return !!found_role;
};

export const convertTableValues = (
  item: ItemSearchRuleResponse,
  itemText: string,
  globalCountries?: Country[]
): Record<string, string> => {
  const countries: string[] = defaultTo(get(item, TableFieldsEnum.COUNTRY), [
    "",
  ]).map((country: string) =>
    getCountriesAbbreviations(
      defaultTo(country, ""),
      defaultTo(globalCountries, [])
    )
  );

  const ruleType: string = includes(
    [TableValueEnum.WHITE_LIST, TableValueEnum.FIRST_LEVEL],
    itemText
  )
    ? itemText === TableValueEnum.WHITE_LIST
      ? get(item, PathsEnum.RULE_NAME, "")
      : defaultTo(get(item, PathsEnum.ENTITY_NAME_ELASTIC, ""), itemText)
    : itemText;

  return {
    [TableFieldsEnum.CREATED]: format(
      new Date(get(item, TableFieldsEnum.CREATED, 0)),
      FormatDateEnum.DD_MM_YYYY
    ),
    [TableFieldsEnum.COUNTRY]: countries.join(",\n"),
    [TableFieldsEnum.ENTITY_NAME]: defaultTo(
      TEXT_TRANSFORM_COLUMNS[itemText],
      LabelEnum.NO_APPLY
    ),
    [TableFieldsEnum.CONFIG_TYPE]: TEXT_TRANSFORM_COLUMNS[itemText],
    [TableFieldsEnum.SUB_TYPE]: TEXT_TRANSFORM_COLUMNS[itemText],
    [TableFieldsEnum.TYPE]: TEXT_TRANSFORM_COLUMNS[ruleType],
    [TableFieldsEnum.MERCHANT_NAME]: TEXT_TRANSFORM_COLUMNS[itemText],
    [TableFieldsEnum.STATUS]: TEXT_TRANSFORM_COLUMNS[itemText],
    [TableFieldsEnum.RULE]: TEXT_TRANSFORM_COLUMNS[itemText],
  };
};

const getCountriesAbbreviations = (
  country: string,
  countries: Country[]
): string => {
  return get(
    countries.find((c: Country) => c.name === country),
    "code",
    country.substr(0, 3).toUpperCase()
  );
};

export const buildOneCell = (
  typeCell: TableBodyCellEnum,
  value1: string,
  value2?: string,
  style: ICellRow = { align: "left", spacing: 0 },
  status?: string,
  tabName?: string
): ITableCellProps => {
  switch (typeCell) {
    case TableBodyCellEnum.TAG:
      return {
        type: TableBodyCellEnum.TAG,
        props: {
          text: value1,
          cellProps: {
            align: style.align,
            spacing: style.spacing,
          },
          color: setTagColor(tabName, status),
        },
      };

    case TableBodyCellEnum.TITLE:
      return {
        type: TableBodyCellEnum.TITLE,
        props: {
          type: "twoLines",
          line1: value1,
          line2: value2,
          cellProps: {
            align: style.align,
            spacing: style.spacing,
          },
        },
      };
    default:
      return {
        type: TableBodyCellEnum.TEXT,
        props: {
          type: "oneLine",
          line1: value1,
          cellProps: {
            align: style.align,
            spacing: style.spacing,
          },
        },
      };
  }
};

export const setTagColor = (tabName?: string, status?: string) => {
  if (tabName === LabelEnum.FIRST_LEVEL_RULES) {
    return status === StatusEnum.RULES_ENABLED
      ? ColorEnum.SUCCESS
      : ColorEnum.INFO;
  }
  return status === StatusEnum.RULES_ENABLED
    ? ColorEnum.SUCCESS
    : ColorEnum.WARNING;
};

export const convertTimestampToDate = (value: number): string => {
  const sizeNumber: number = String(value).length;
  const valueToConvert: number = sizeNumber >= 13 ? value : value * 1000;
  return format(new Date(valueToConvert), FormatDateEnum.DDMMYYY_WITH_SLASH);
};

export const convertTimestampToDateTime = (value: number): string => {
  return format(new Date(value), FormatDateEnum.DD_MM_YY_HH_MM);
};

export const convertTimestampToTime = (value: string): string => {
  const timeStamp: number =
    size(value) === 10 ? toNumber(value) * 1000 : toNumber(value);

  return format(new Date(timeStamp), FormatDateEnum.HH_MM_SS);
};

export const convertTimestampToDateS = (value: string): string => {
  const timeStamp: number =
    size(value) === 10 ? toNumber(value) * 1000 : toNumber(value);

  if (value == "") return "";
  return format(new Date(timeStamp), FormatDateEnum.DDMMYYY_WITH_SLASH);
};

export const addPlusToPhoneNumber = (value: string): string => {
  const [prefix, number]: string[] = split(value, " ");

  return `+${prefix} ${join(number.match(/.{1,3}/g), " ")}`;
};

export const isOperatorEqualOrDifferent = (operator: string): boolean => {
  return ["=", "!="].includes(operator);
};

export const isFormEditMode = (ruleId: string | undefined): boolean => {
  return !isUndefined(ruleId);
};

export const isFormWhiteListMode = (): boolean => {
  return window.location.href.indexOf(FieldsRule.WHITE_LIST) > -1;
};

export const mapRules = (
  rules: Condition[],
  request: RuleRequest
): RuleRequest => {
  rules.map((rule) => {
    if (rule.label) {
      request.rule[rule.label] =
        rule.operator === OPERATORS.ALL
          ? OPERATORS.EQUAL_PIPE_ALL
          : `${rule.operator}|${mapRuleValues(get(rule, "value", []))}`;
    }
  });

  return request;
};

export const mapRuleValues = (values: string[]): string => {
  let final_value: string = "";
  const separator: string = ",";

  values.map((val: string, index: number) => {
    if (index === values.length - 1) {
      final_value += val;
      return;
    }
    final_value += `${val}${separator}`;
  });

  return final_value;
};

export const buildGenerateConditions = (
  fetchRule:
    | SearchRuleByIdResponse
    | SearchRequestRuleByIdResponse
    | GetRuleByIdResponse
    | RuleGeneralRequest,
  generatedCondition: IConditionForm[],
  ruleSchema: RulesSchema,
  generatedSpecialCondition?: IConditionForm[]
): void => {
  let fetchRuleGeneral = fetchRule.rule;
  if (ruleSchema == RulesSchema.RULEMONITOR) {
    fetchRule = fetchRule as GetRuleByIdResponse;
    fetchRuleGeneral = fetchRule.rule.rule;
  }

  Object.entries(fetchRuleGeneral).forEach(([key, value]) => {
    if (
      !isUndefined(value) &&
      !isEmpty(key) &&
      !isEmpty(value) &&
      includes(ALLOWED_FIELD_RESET_FORM, key)
    ) {
      const operatorValue: string = includesAllOperator(value.toString())
        ? OPERATORS.ALL
        : value.toString().split("|")[0];
      const fieldValue: string[] = includesAllOperator(value.toString())
        ? []
        : value.toString().split("|")[1].split(",");

      if (
        SPECIAL_CONDITIONS.includes(key) &&
        !isUndefined(generatedSpecialCondition)
      ) {
        generatedSpecialCondition.push({
          label: key,
          value: fieldValue,
          operator: operatorValue,
        });
      } else {
        generatedCondition.push({
          label: key,
          value: fieldValue,
          operator: operatorValue,
        });
      }
    }
  });
};

export const buildConditionsForEditedRule = (
  conditions: Condition[],
  generatedCondition: IConditionForm[]
): void => {
  conditions.forEach((condition: Conditions) => {
    generatedCondition.push({
      id: get(condition, "id", ""),
      label: get(condition, "label", ""),
      value: get(condition, "value", []),
      operator: get(condition, "operator", ""),
    });
  });
};

export const includesAllOperator = (value: string): boolean => {
  return value.includes(OPERATORS.EQUAL_PIPE_ALL);
};

export const buildObjectToResetForm = (
  updatedRule:
    | SearchRuleByIdResponse
    | SearchRequestRuleByIdResponse
    | GetRuleByIdResponse
    | RuleGeneralRequest,
  generatedCondition: IConditionForm[],
  generatedSpecialCondition: IConditionForm[],
  ruleId: string,
  ruleSchema: string,
  setDefaultCountries?: Dispatch<SetStateAction<string[]>>,
  setDefaultHierarchy?: Dispatch<
    SetStateAction<object | OptionsSelectSearchBy>
  >,
  setDefaultService?: Dispatch<SetStateAction<string>>,
  hierarchyValidationPath?: string
): ISecurityRuleForm => {
  let generalRuleUpdated:
    | RuleGeneralRequest
    | SearchRuleByIdResponse
    | SearchRequestRuleByIdResponse
    | GetRuleByIdResponse = updatedRule;
  let subtype: string = get(generalRuleUpdated, "subType", "");

  if (ruleSchema == RulesSchema.RULEMONITOR) {
    const updRule: GetRuleByIdResponse = updatedRule as GetRuleByIdResponse;
    generalRuleUpdated = updRule.rule;
    subtype = TypeToSubtypeRules[get(generalRuleUpdated, "type", "")];
  }

  const service: string = get(generalRuleUpdated, "service", "");
  const defaultHierarchy: OptionsSelectSearchBy =
    subtype === SubTypeEnum.SECURITY_COMMERCE ||
    subtype === SubTypeEnum.COMMERCE
      ? {
          categoryName: "",
          categoryId: "",
          id: get(generalRuleUpdated, "entityName", ""),
          secondaryText: get(generalRuleUpdated, "merchantId", ""),
          text: get(generalRuleUpdated, "merchantName", ""),
        }
      : ({} as OptionsSelectSearchBy);
  if (!!(setDefaultCountries && setDefaultHierarchy && setDefaultService)) {
    setDefaultCountries(get(generalRuleUpdated, "country", []));
    setDefaultHierarchy(defaultHierarchy);
    setDefaultService(service);
  }

  generatedCondition.forEach((item) => {
    if (item.label === "transactionConstitutionalCountry")
      item.transactionConstitutionalCountry = item.value;
  });

  const formValues: ISecurityRuleForm = {
    service,
    action: get(generalRuleUpdated, "rule.action", ""),
    code: get(generalRuleUpdated, "rule.code", ""),
    hasSpecialCond: get(generalRuleUpdated, "rule.hasSpecialCond", false),
    condition: generatedCondition,
    specialCondition: generatedSpecialCondition,
    continents: get(generalRuleUpdated, "continents", []),
    countries: get(generalRuleUpdated, "country", []),
    country: get(generalRuleUpdated, "country", []),
    generalAction: get(generalRuleUpdated, "rule.generalAction", ""),
    hierarchy: defaultHierarchy,
    isUpdatedRule: isFormEditMode(ruleId),
    kind: get(generalRuleUpdated, "rule.kind", ""),
    subKind: get(generalRuleUpdated, "rule.hasSpecialCond", false)
      ? Values.RULES_KIND_SPECIAL
      : Values.RULES_KIND_STRICT,
    message: get(generalRuleUpdated, "rule.message", ""),
    name: get(generalRuleUpdated, "rule.name", ""),
    partnerValidator: get(generalRuleUpdated, "rule.partnerValidator", ""),
    periodicity: get(generalRuleUpdated, "rule.periodicityCount", ""),
    provider: get(
      generalRuleUpdated,
      validateProviderPath(
        get(generalRuleUpdated, PathsEnum.GENERAL_ACTION_DYNAMO)
      ),
      ""
    ),
    regions: get(generalRuleUpdated, "regions", []),
    stateTrx: get(generalRuleUpdated, "rule.transactionStatus", ""),
    subType: subtype,
    type: get(generalRuleUpdated, "type", ""),
    ignore3DS: get(generalRuleUpdated, "rule.ignore3DS", false),
  };

  if (get(generalRuleUpdated, PathsEnum.MAX_AMOUNT)) {
    formValues.amountSum = get(generalRuleUpdated, "rule.maxAmount");
    formValues.periodicity = get(
      generalRuleUpdated,
      PathsEnum.PERIODICITY_SUM,
      ""
    );
    formValues.stateTrx = get(
      generalRuleUpdated,
      PathsEnum.TRANSACTION_STATUS_SUM,
      ""
    );
  }
  if (get(generalRuleUpdated, PathsEnum.LIMIT)) {
    formValues.trxCount = get(generalRuleUpdated, "rule.limit");
    formValues.stateTrx = get(
      generalRuleUpdated,
      PathsEnum.TRANSACTION_STATUS_COUNT,
      ""
    );
  }
  if (get(generalRuleUpdated, "rule.maxAmountCurrency"))
    formValues.currency = get(generalRuleUpdated, "rule.maxAmountCurrency");

  const commerceTypeValue: string = !isEmpty(
    get(updatedRule, hierarchyValidationPath!)
  )
    ? SubTypeEnum.COMMERCE_WITH_HIERARCHY
    : SubTypeEnum.COMMERCE_WITHOUT_HIERARCHY;

  set(formValues, "commerceType", commerceTypeValue);

  return formValues;
};

export const buildObjectToResetFormForEditedRule = (
  ruleDetails: RuleDetail,
  rule: SecurityRuleData,
  generatedCondition: IConditionForm[],
  generatedSpecialCondition: IConditionForm[],
  setDefaultCountries?: Dispatch<SetStateAction<string[]>>,
  setDefaultHierarchy?: Dispatch<
    SetStateAction<object | OptionsSelectSearchBy>
  >,
  setDefaultService?: Dispatch<SetStateAction<string>>
): ISecurityRuleForm => {
  const subtype: string = get(ruleDetails, "subType", "");
  const service: string = get(ruleDetails, "service", "");
  const maxAmount: number = get(rule, "rule.maxAmount");
  const limit: number = get(rule, "rule.limit");
  const maxAmountCurrency: string = get(rule, "rule.maxAmountCurrency");
  const trxCount: string = get(rule, "trxCount");
  const periodicity: string | undefined = get(rule, "periodicity");
  const stateTrx: string = get(rule, "stateTrx");
  const amountSum: string = get(rule, "amountSum");
  const currency: string = get(rule, "currency");

  const defaultHierarchy: OptionsSelectSearchBy =
    subtype === SubTypeEnum.COMMERCE
      ? {
          categoryName: "",
          categoryId: "",
          id: get(ruleDetails, "entityName", ""),
          secondaryText: get(ruleDetails, "merchantId", ""),
          text: get(ruleDetails, "merchantName", ""),
        }
      : ({} as OptionsSelectSearchBy);
  if (setDefaultCountries && setDefaultHierarchy && setDefaultService) {
    setDefaultCountries(defaultTo(get(ruleDetails, "country"), []));
    setDefaultHierarchy(defaultHierarchy);
    setDefaultService(service);
  }

  const formValues: ISecurityRuleForm = {
    service,
    action: get(rule, "generalAction", ""),
    code: get(rule, "code", ""),
    condition: generatedCondition,
    continents: get(ruleDetails, "continents", []),
    countries: get(ruleDetails, "country", []),
    country: get(ruleDetails, "country", []),
    generalAction: get(rule, "generalAction", ""),
    hierarchy: defaultHierarchy,
    kind: get(rule, "kind", ""),
    message: get(rule, "message", ""),
    name: get(rule, "name", ""),
    partnerValidator: get(rule, "provider", ""),
    provider: get(rule, "provider", ""),
    regions: get(rule, "regions", []),
    subType: subtype,
    type: get(ruleDetails, "type", ""),
    ignore3DS: get(rule, "ignore3DS", false),
    hasSpecialCond: get(rule, "hasSpecialCond", false),
    specialCondition: generatedSpecialCondition,
  };

  if (maxAmount) set(formValues, "amountSum", maxAmount);
  if (limit) set(formValues, "trxCount", limit);
  if (maxAmountCurrency) set(formValues, "currency", maxAmountCurrency);
  if (trxCount) set(formValues, "trxCount", trxCount);
  if (periodicity) set(formValues, "periodicity", periodicity);
  if (stateTrx) set(formValues, "stateTrx", stateTrx);
  if (amountSum) set(formValues, "amountSum", amountSum);
  if (currency) set(formValues, "currency", currency);

  return formValues;
};

export const validateProviderPath = (ruleAction: string): string => {
  return VALID_GENERAL_ACTIONS.includes(ruleAction as GeneralActionEnum)
    ? PathsEnum.PROVIDER
    : PathsEnum.RULE_ACTION;
};

export const getWhiteListCondition = (
  rule: ItemSearchRuleResponse,
  type: string
): IWhiteListCondition[] => {
  const conditionList: object = pick(rule.rule, CONDITION_FIELDS[type]);
  const response: IWhiteListCondition[] = [];

  Object.keys(conditionList).forEach((condition) => {
    const item: string = get(conditionList, condition, "");
    const value: string[] = item.split("|")[1].split(",");

    response.push({
      total: value.length,
      label: CONDITION_LABEL[condition],
      value,
    });
  });

  return response;
};

export const checkIsGeneralRule = (path: string): boolean => {
  return path.includes(UrlPaths.GENERAL_RULE);
};

export const checkIsWhiteList = (path: string): boolean => {
  return path.includes(UrlPaths.WHITE_LIST);
};

export const getSecureServiceName = (
  condition: string,
  separator: string
): string => {
  const values: string[] = condition.split(separator);
  const newValues: string[] = values.map(
    (val: string) => SECURE_SERVICE_NAME[val]
  );

  return newValues.join(separator);
};

export const generateSectionCondition = (
  item: RuleGeneralRequest,
  configurationValue: ConfigurationResponse | undefined,
  allowConditions: string[],
  title: string
): ICardTableProps => {
  const rows: any[][] = [];
  allowConditions.forEach((field: string) => {
    if (get(item, `rule.${field}`)) {
      const row: any[] = [];
      const value: string = get(item, `rule.${field}`, "");
      const values: string[] = value.split("|");
      const condition: Conditions | undefined = get(
        configurationValue,
        "conditions",
        []
      ).find((condition: Conditions) => condition.field === field);
      const title: string | undefined = get(condition, "title");
      const isSecureService: boolean = isEqual(
        title,
        TitleSection.SECURE_SERVICE
      );

      row.push({
        text: title,
      });
      row.push({
        text: configurationValue?.operators?.find(
          (operator: Operators) => operator.value === values[0]
        )?.title,
      });
      row.push({
        text: isSecureService
          ? getSecureServiceName(values[1], ",")
          : values[1],
      });
      rows.push(row);
    }
  });

  return {
    title,
    isLoading: false,
    headers: [
      {
        title: "Etiqueta",
      },
      {
        title: "Operador",
      },
      {
        title: "Valor",
      },
    ],
    rows,
  };
};

export const generateSectionSpeed = (
  item: RuleGeneralRequest,
  configurationValue: ConfigurationResponse | undefined
): ICardTableProps => {
  const rows: any[][] = [];
  let headers: any[] = [];
  const row: any[] = [];
  if (item.rule.periodicitySum) {
    headers = [
      {
        title: "Acción",
      },
      {
        title: "Periodicidad",
      },
      {
        title: "Valor",
      },
      {
        title: "Moneda",
      },
      {
        title: "Estado",
      },
    ];
    row.push({ text: "Sumar" });

    row.push({
      text: configurationValue?.periodicity?.find(
        (periodicity: Periodicity) =>
          periodicity.value === item.rule.periodicitySum
      )?.title,
    });
    row.push({ text: get(item, "rule.maxAmount", "") });
    row.push({ text: get(item, "rule.maxAmountCurrency", "") });
    row.push({
      text: TRANSACTION_STATUS[get(item, "rule.transactionStatusSum", "")],
    });
    rows.push(row);
  } else {
    headers = [
      {
        title: "Acción",
      },
      {
        title: "Periodicidad",
      },
      {
        title: "Valor",
      },
      {
        title: "Estado",
      },
    ];
    row.push({ text: "Contar" });
    row.push({
      text: configurationValue?.periodicity?.find(
        (periodicity: Periodicity) =>
          periodicity.value === item.rule.periodicityCount
      )?.title,
    });
    row.push({ text: item.rule.limit });
    row.push({
      text: TRANSACTION_STATUS[get(item, "rule.transactionStatusCount", "")],
    });
    rows.push(row);
  }

  return {
    title: SubTitleSection.RULES_SPEED_CONDITION,
    isLoading: false,
    headers,
    rows,
  };
};

export const generateSectionResume = (
  item: RuleGeneralRequest,
  configuration: ConfigurationResponse | undefined
): ICardListProps => {
  return {
    title: "Configuración",
    orientation: "horizontal",
    separator: false,
    isLoading: false,
    items: [
      {
        label: "Acción general",
        text: defaultTo(
          get(configuration, "generalActions", []).find(
            (action: GeneralActions) => action.name === item.rule.generalAction
          ),
          { title: "" }
        ).title,
        secondaryText: "",
      },
      {
        label: "Proveedor",
        text: defaultTo(
          get(configuration, "providers", []).find(
            (provider: Providers) =>
              provider.name === item.rule.partnerValidator
          ),
          { title: "" }
        ).title,
        secondaryText: "",
      },
      {
        label: "Código",
        text: defaultTo(
          get(configuration, "codes", []).find(
            (code: Codes) => code.code === item.rule.code
          ),
          { title: "" }
        ).title,
        secondaryText: "",
      },
      {
        label: "Mensaje",
        text: defaultTo(
          get(configuration, "messages", []).find(
            (message: Messages) => message.title === item.rule.message
          ),
          { title: "" }
        ).title,
        secondaryText: "",
      },
    ],
  };
};

export const checkValidCurrencies = (
  selectedCurrencyValue: string,
  countries: string[],
  currencies: Record<string, IOptionSelection[]> | undefined
): string => {
  const validCurrencies: string[] = [];
  if (currencies && countries.length > 0) {
    countries.map((country: string) => {
      const val: string = `CURRENCY_${country.toUpperCase().replace(" ", "_")}`;

      if (currencies[val]) {
        currencies[val].map((currency: { value: string }) => {
          if (!validCurrencies.includes(currency.value))
            validCurrencies.push(currency.value);
        });
      }
    });

    if (!validCurrencies.includes(selectedCurrencyValue))
      selectedCurrencyValue = get(
        currencies[`CURRENCY_${countries[0].toUpperCase().replace(" ", "_")}`],
        "[0].value",
        ""
      );
  }
  return selectedCurrencyValue;
};

export const getRawNumber = (formattedNumber: string = ""): string => {
  return formattedNumber.replace(/\./g, "").replace(",", ".");
};

export const getCountryReferencesCodesByCountryName = (
  geographyListCountries: Country[],
  countriesSearch: string[]
): (string | undefined)[] => {
  return geographyListCountries
    .filter((data: Country) => countriesSearch.includes(data.name))
    .map((data: Country) => data.referenceCode);
};

export const getRegionsReferencesCodesByCountryReferencesCodes = (
  geographyListRegions: Region[],
  countriesReferencesCodes: (string | undefined)[]
): (string | undefined)[] => {
  return geographyListRegions
    .filter((data: Region) => countriesReferencesCodes.includes(data.code))
    .map((data: Region) => data.referenceCode);
};

export const getContinentNameByRegionReferencesCodes = (
  geographyListContinents: Continent[],
  regionsReferencesCodes: (string | undefined)[]
): (string | undefined)[] => {
  return geographyListContinents
    .filter((data: Continent) => regionsReferencesCodes.includes(data.code))
    .map((data: Continent) => data.name);
};

export const getContinentName = (
  countries: string[],
  geographyList: GeographyList | undefined
) => {
  const countryReferencesCodes: (string | undefined)[] =
    getCountryReferencesCodesByCountryName(
      defaultTo(get(geographyList, "countries"), []),
      countries
    );
  const regionReferencesCodes: (string | undefined)[] =
    getRegionsReferencesCodesByCountryReferencesCodes(
      defaultTo(get(geographyList, "regions"), []),
      countryReferencesCodes
    );
  const continentsName: (string | undefined)[] =
    getContinentNameByRegionReferencesCodes(
      defaultTo(get(geographyList, "continents"), []),
      regionReferencesCodes
    );
  return {
    continentsName,
    countryReferencesCodes,
  };
};

export const transformCardName = (cardName: string): string => {
  const map_obj: object = {
    á: "a",
    Á: "A",
    é: "e",
    É: "E",
    í: "i",
    Í: "I",
    Ñ: "N",
    ñ: "n",
    ó: "o",
    Ó: "O",
    ú: "u",
    Ú: "U",
  };
  let str: string;

  str = defaultTo(cardName, "").replace(
    /[áéíóúÁÉÍÓÚÑñ]/gi,
    (matched: string): string => map_obj[matched]
  );
  str = str.replace(/[^\x00-\x7F]/g, "");
  str = str.replace(/[^a-zA-Z 0-9]+/g, "");

  return str;
};
export const getAvailableConditions = (props: {
  action: string;
  conditions: Conditions[];
  countries: string[];
  entityName: string;
  provider: string;
  subType: string;
}): Conditions[] => {
  let filter: Conditions[] = [];

  if (!isEmpty(props.action) && !isEmpty(props.provider)) {
    filter = props.conditions.filter((condition: Conditions) => {
      if (isEqual(props.subType, SubTypeEnum.COUNTRY)) {
        if (get(props, "countries.length", 0) > 1) {
          return !COUNTRIES_CONDITIONS.includes(get(condition, "field", ""));
        } else {
          return !COUNTRY_CONDITIONS.includes(get(condition, "field", ""));
        }
      }

      return !NOT_RULE_CONDITIONS.includes(get(condition, "field", ""));
    });

    //Hide conditions when it's general rule or security rule
    filter = filter.filter((condition: Conditions) => {
      return !HIDE_SECURITY_RULES_CONDITIONS.includes(
        get(condition, "field", "")
      );
    });

    //Hide conditions when the entity name is "customer"
    if (isEqual(props.entityName, GroupEnum.CUSTOMER)) {
      filter = filter.filter((condition: Conditions) => {
        return !HIDE_CUSTOMER_CONDITIONS.includes(get(condition, "field", ""));
      });
    }

    //Hide conditions when it's authenticate action and 3DS method
    if (
      props.action === GeneralActionEnum.AUTHENTICATE &&
      props.provider === ActionEnum.THREEDS
    ) {
      filter = filter.filter((condition) => {
        return !HIDE_SECURITY_RULES_3DS_CONDITIONS.includes(
          get(condition, "field", "")
        );
      });
    }

    //Hide conditions when it's authenticate action and OTP method
    if (
      props.action === GeneralActionEnum.AUTHENTICATE &&
      props.provider === ActionEnum.OTP
    ) {
      filter = filter.filter((condition) => {
        return !HIDE_SECURITY_RULES_OTP_CONDITIONS.includes(
          get(condition, "field", "")
        );
      });
    }
  }

  return filter;
};

export const getAvailableConditionsFilter = (props: {
  action: string;
  conditions: Conditions[];
  tabName: string;
}): Conditions[] => {
  let filter: Conditions[] = [];

  filter = props.conditions.filter(
    (condition) => !NOT_RULE_CONDITIONS.includes(get(condition, "field", ""))
  );

  if (props.tabName === LabelEnum.WHITE_LISTS) {
    filter = filter.filter((condition) => {
      return !HIDE_WHITE_LIST_CONDITIONS.includes(get(condition, "field", ""));
    });
  } else {
    if (!isEmpty(props.action)) {
      //Hide conditions when it's general rule or security rule
      filter = filter.filter((condition) => {
        return !HIDE_SECURITY_RULES_CONDITIONS.includes(
          get(condition, "field", "")
        );
      });
      //Hide conditions when it's authenticate action
      if (props.action === TEXT_TRANSFORM_MODAL[ValueEnum.AUTHENTICATE]) {
        filter = filter.filter((condition) => {
          return !HIDE_SECURITY_GENERIC_AUTHENTICATE_CONDITIONS.includes(
            get(condition, "field", "")
          );
        });
      }
    }
  }

  return filter;
};

export const isAmex = (value: string) => {
  return value.startsWith("37") || value.startsWith("34");
};
